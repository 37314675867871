import React, { useEffect, useState } from "react";
import { Grid } from "basis";
import styled from "styled-components";
import { useQueryParam, StringParam, BooleanParam } from "use-query-params";

import { useMerchantAuth } from "../../../core/auth";
import * as api from "../utils/api";
import { ErrorMessage, ERROR_CODES, LoadingMessage } from "../../../components";
import FileList from "./components/FileList";
import { logger } from "../../../core";
import { NavigationalLayout } from "../components";
import { Text } from "../../../components";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import NavigationHeader from "./components/NavigationHeader";

const Scene = () => {
  const [paginationKey, setPaginationKey] = useState("");
  const [filePath] = useQueryParam("path", StringParam);
  const [isDefaultView] = useQueryParam("dv", BooleanParam);
  const [path, setPath] = useState("");
  const [files, setFiles] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { error: authError, profile } = useMerchantAuth();

  const StyledContainer = styled.div`
    border-radius: 10px;
    background-color: ${COLOR.WHITE};
    padding: 30px 15px;
    margin-top: 30px;

    @media (min-width: ${BREAKPOINT.SIZE_MOBILE}) {
      padding: 30px;
    }
  `;

  const getSettlementFiles = async () => {
    try {
      if (!profile) return;
      setLoading(true);
      const response = await api.getAllSettlementFiles({
        merchantId: profile?.merchantId,
        paginationKey,
        prefix: filePath,
        defaultView: isDefaultView,
      });

      if (!response) {
        setError(ERROR_CODES.INVALID_REQUEST);
        return;
      }
      setPaginationKey(response.paginationKey);
      setPath(response.prefix);
      if (response.prefix) {
        setShowNavigation(true);
      }
      if (response.objects && response.objects.length > 0) {
        setFiles((current) => [...current, ...response.objects]);
      }
    } catch (err) {
      logger.error(err.message);
      setError(ERROR_CODES.DEFAULT);
    } finally {
      setLoading(false);
    }
  };

  const onFolderClick = async ({ prefix }) => {
    let baseUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    if (!prefix || prefix === "/") {
      document.location.href = baseUrl;
      return;
    }
    document.location.href = `${baseUrl}?path=${prefix}`;
  };

  const onFileClick = async ({ path, name }) => {
    await api.downloadSettlementFile({
      key: path,
      name,
      merchantId: profile.merchantId,
    });
  };

  useEffect(() => {
    setLoading(true);
    setPaginationKey("");
    setFiles([]);
    getSettlementFiles();
  }, [profile?.merchantId, filePath, isDefaultView]);

  const Error = ({ type }) => (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={4}>
        <Grid.Item colSpan="all" colSpan-lg="2-10" colSpan-xl="4-8">
          <ErrorMessage type={type} />
        </Grid.Item>
      </Grid>
    </NavigationalLayout>
  );

  if (authError) {
    return <Error type={authError} />;
  }

  if (error) {
    return <Error type={error} />;
  }

  if (loading) {
    return <LoadingMessage />;
  }

  return (
    <NavigationalLayout>
      <Text textStyle="heading3">View and download reports</Text>

      <StyledContainer>
        <Grid preset="page" rowsGap={8}>
          <Grid.Item colSpan="all">
            <NavigationHeader
              filePath={path}
              show={showNavigation}
              onLinkClick={(prefix) => onFolderClick({ prefix })}
            />

            <FileList
              files={files}
              onFileClick={({ path, name }) => onFileClick({ path, name })}
              onFolderClick={(prefix) => onFolderClick({ prefix })}
            />
          </Grid.Item>
        </Grid>
      </StyledContainer>
    </NavigationalLayout>
  );
};

export default Scene;
