import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../../components/theme";
import { Icon } from "../../../../components";

const StyledContainer = styled.div`
  .error {
    border-bottom: 5px solid ${COLOR.RED};
    display: block;
    width: 100%;
    padding: 15px 0;
    margin-bottom: 15px;

    p {
      color: ${COLOR.RED} !important;
    }
  }
`;

const File = styled.div`
  inline-size: 100px;
  overflow-wrap: break-word;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLOR.GREY_10};
  width: 100%;
  padding: 8px 0px 8px 0px;
`;

const NoFileContainer = styled.div`
  overflow-wrap: break-word;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLOR.GREY_10};
  width: 100%;
  padding: 8px 16px 8px 16px;
  color: ${COLOR.DARK_GREY};
`;

const IconContainer = styled.div`
  width: 32px;
  height: 10%;
  color: ${COLOR.LINK_BLUE};
  padding: 6px 0px 6px 12px;
`;

const ArrowContainer = styled.div`
  width: 6px;
  height: 10%;
  color: ${COLOR.LINK_BLUE};
  padding: 6px 0px 6px 0px;
`;
const NameContainer = styled.div`
  width: auto;
  height: 100%;
  color: ${COLOR.LINK_BLUE};
  padding: 8px 16px 8px 16px;
  word-wrap: break-word;
`;

const FileList = ({ files, onFileClick, onFolderClick }) => {
  const onClick = (event, file) => {
    event.preventDefault()
    if (file.type == "folder") {
      onFolderClick(file.key);
      return;
    }
    onFileClick({ path: file.key, name: file.name });
  };

  if (!files || files.length === 0) {
    return <StyledContainer>
        <NoFileContainer>There are no files.</NoFileContainer>
    </StyledContainer>;
  }
  return (
    <StyledContainer>
        {files.map((file, index) => {
          if (file.name !== "")
            return (
              <File
                key={index}
                onClick={(event) => {;
                  onClick(event, file);
                }}
              >
                {file.type !== "folder" && (
                  <IconContainer>
                    <Icon type="file" display="contents" color={COLOR.LINK_BLUE} />
                  </IconContainer>
                )}
                <NameContainer>
                  <span>{file.name}</span>
                </NameContainer>
                {file.type === "folder" && (
                  <ArrowContainer>
                    <Icon type="arrowChevronRight" display="contents" color={COLOR.LINK_BLUE} />
                  </ArrowContainer>
                )}
              </File>
            );
        })}
    </StyledContainer>
  );
};

export default FileList;
