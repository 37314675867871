import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLOR } from "../../../../components/theme";
import { Icon } from "../../../../components";

const DEFAULT_ROOT_PATH = "Reports";

const NavigationHeader = ({ filePath, onLinkClick }) => {
  const Container = styled.div`
    background: ${COLOR.GREY_10};
    width: 100%;
    padding: 8px 0px 8px 0px;
    float: left;
  `;

  const DivSelect = styled.div`
    color: ${COLOR.DARK_GREY};
    padding: 12px 16px 0px 16px;
    width: auto;
    height: 100%;
    word-wrap: break-word;
  `;

  const PathContainer = styled.div`
    overflow-wrap: break-word;
    font-size: 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${COLOR.GREY_10};
    float: left;
  `;

  const IconContainer = styled.div`
    width: 10px;
    height: 5%;
    padding: 12px 2px 8px 2px;
  `;

  const Path = styled.div`
    width: auto;
    height: 100%;
    color: ${COLOR.LINK_BLUE};
    padding: 8px 16px 8px 16px;
    word-wrap: break-word;
    cursor: pointer;
  `;

  const [paths, setPaths] = useState([]);

  useEffect(() => {
    let keys = [{ key: "", name: DEFAULT_ROOT_PATH }];

    if (!filePath) {
      return setPaths(keys);
    }

    let p = filePath.split("/").filter((p) => p !== "");
    let parentKey = "";

    keys = [
      ...keys,
      ...p.map((p) => {
        let key = parentKey + p + "/";
        parentKey = key;
        return { key: key, name: p };
      }),
    ];

    setPaths(keys);
  }, [filePath]);

  if (paths.length === 0) {
    return (
      <Container>
        <DivSelect>${DEFAULT_ROOT_PATH}</DivSelect>
      </Container>
    );
  }

  return (
    <Container>
      {paths.map((path, index) => {
        if (path) {
          if (index === paths.length - 1) {
            return (
              <PathContainer key={index}>
                <DivSelect>{path.name}</DivSelect>
              </PathContainer>
            );
          }

          return (
            <PathContainer key={index}>
              <Path
                onClick={(event) => {
                  event.preventDefault();
                  onLinkClick(path.key);
                }}
              >
                {path.name}
              </Path>
              <IconContainer>
                <Icon
                  type="arrowChevronRight"
                  size="32px"
                  display="contents"
                  color={COLOR.BLACK_50}
                />
              </IconContainer>
            </PathContainer>
          );
        }
      })}
    </Container>
  );
};

export default NavigationHeader;
